/**
 * @file auth.service.ts
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthTokenService } from '../authToken/auth-token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor( private readonly tokenService: AuthTokenService,
    private readonly router: Router) { }

  /**
   * @description This method is used to set the token for login in this service.
   * @param idToken contains token for the login 
   * @returns void
   */
  public login(idToken: string): void {
    this.tokenService.azureIdToken =idToken;
    this.tokenService.loginDateTime = new Date().toString();
    this.router.navigate(['/main']);
  }
  /**
   * @description This function is used to return the boolean if token is present for login.
   * @returns boolean value if token is present or not
   */
  get isAuthorizedByAzureB2c(): boolean {
    return !!this.tokenService.azureIdToken;
  }

  /**
 * @description This method is used clear the local and Session storage after logout and navigate back to login page.
 * @returns void
 */
  public logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace(environment.b2cSignInFlow);
  }

  public setDisplayName(): void {
    this.tokenService.setFirstName();
    this.tokenService.setLastName();
  }
}
