import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { _HTTPInterceptorsProviders } from './core/interceptors';
import { SanitizeHtmlPipe } from './core/pipes/sanitize-html.pipe';
import { LoaderComponent } from './core/components/loader/loader.component';
import { LoaderService } from './core/services/loader/loader.service';
import { ErrorDialogComponent } from './core/components/error/error-dialog.component';

@NgModule({
  declarations: [
    ErrorDialogComponent,
    AppComponent,
    SanitizeHtmlPipe,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [_HTTPInterceptorsProviders, LoaderService],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
