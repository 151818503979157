/**
 * @file loader.component.ts
 * @description A component to show loader
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppConstants } from 'src/app/app.constants';
import { Loader } from '../../interfaces/loader.interface';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-loader',
  styleUrls: ['./loader.component.scss'],
  templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit, OnDestroy {
  private static readonly className = 'LoaderComponent';

  private subscription: Subscription = new Subscription();
  public show = false;
  public message = AppConstants.LOADER.DEFAULT_MESSAGE;

  /**
   * @param  LoaderService loaderService
   */
  constructor(private readonly loaderService: LoaderService) { }

  public ngOnInit(): void {
    this.subscription = this.loaderService.loader.subscribe((state: Loader) => {
      console.log(state);
      this.message = state.message;
      this.show = state.show;
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
