export class AppConstants {
  public static readonly LABELS = {
    id_token: 'id_token',
    login_date_time: 'login_date_time',
    NAV_COMPONENT: {
      IMPLEMENTATION_SUMMARY: 'Implementation Executive Summary',
      GO_LIVE: 'Go Live',
    },
    FOOTER_COMPONENT: {
      COPY_RIGHT_TEXT: '© 2022 EmpiRx Health, LLC. All Rights Reserved.',
      COMPLETED_STATUS: 'Complete',
      ON_TRACK: 'On Track',
      AT_RISK: 'At Risk',
      NOT_STARTED: 'Not Started',
    },
    HEADER_COMPONENT: {
      LOGOUT_TEXT: 'Logout',
    },
    STATUS_UPDATE_COMPONENNT: {
      STATUS_TEXT: 'Status Update',
      KEY_ACCOMPLISHMENTS: 'Key Accomplishments',
      WHATS_NEXT: 'What’s Next?',
      KEY_DECISION: 'Key Decisions',
      DECISION_NEEDED: 'Decision Needed',
      OWNER: 'Owner',
      DUE_DATE: 'Due Date',
      WATCH_OUT_ITEMS_HEADER: 'Watch Out Items',
      WATCH_OUT_ITEMS: 'Watch Out Item',
      MITIGRTION_PLAN: 'Mitigation Plan',
      OWNERS: 'Owner',
      DUE_DATES: 'Due Date',
      NO_DATA_AVAILABLE: "No Data Available",
    },
    MILESTONE_COMPONENT: {
      NO_AVAILABLE: 'No tasks available for this milestone.',
    }
  };

  public static readonly ERROR = {
    MESSAGES: {
      GENERIC: 'We ran into an unexpected error. Please try again.',
      FATAL: 'We ran into an unexpected error. Please refresh the application.',
      API: 'We are having trouble fetching the data. Please try again later. Sorry for the inconvenience.'
    }
  };

  public static readonly URLS = {
    SF_DATA: `/Client/GetSalesforceStatus`
  };

  public static readonly LOADER = {
    DEFAULT_MESSAGE: 'Loading'
  };
}
