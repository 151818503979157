/**
 * @file error.interceptor.ts
 * @description this file implements interceptor to handler http errors
 */
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AuthService } from '../../services/auth/auth.service';
import { ErrorManagerService, ErrorType } from '../../services/error';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  private static readonly className = 'ErrorInterceptor';
  constructor(
    private readonly errorManager: ErrorManagerService,
    private readonly authService: AuthService,
  ) { }

  /**
   * @description Interceptor to handle http errors
   * @param  HttpRequest request
   * @param  HttpHandler next
   * @returns Observable
   */
  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const retryCount = 2;
    const errorStatus = 401;
    return next.handle(request).pipe(
      retry(retryCount),
      catchError((error: HttpErrorResponse) => {
        if (error.status === errorStatus) {
          this.authService.logout();
        } else {
          this.errorManager.throwError({
            type: ErrorType.ERROR_GENERIC,
            message: error.statusText,
            component: ErrorInterceptor.className,
            title: ''
          });
        }
        return throwError(error);
      })
    );
  }
}
