/**
 * @file auth-token.service.ts
 */
import { Injectable } from '@angular/core';
import { AppConstants } from '../../../app.constants';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {
  private readonly token: string = AppConstants.LABELS.id_token;
  private readonly dateTime: any = AppConstants.LABELS.login_date_time;
  firstName = "";
  lastName = "";
  constructor() { }

  /**
 * @description This method is used to set the token for login.
 * @returns void
 */
  public set azureIdToken(idToken: string | null) {
    idToken != null ? localStorage.setItem(this.token, idToken) : null;
  }

  public set loginDateTime(dateTime: string) {
    localStorage.setItem(this.dateTime, dateTime);
  }
  /**
 * @description This method is used to get the token for login.
 * @returns void
 */
  public get azureIdToken(): string | null {
    return localStorage.getItem(this.token);
  }

  public setFirstName(): void {
    this.firstName = jwtDecode<{ given_name: string }>(this.azureIdToken || '').given_name;
  }

  public setLastName(): void {
    this.lastName = jwtDecode<{ family_name: string }>(this.azureIdToken || '').family_name;
  }
}
