/**
 * @file error-dialog.component.ts
 * @description A component to show error dialog
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';
import { ErrorManagerService, ErrorObject } from '../../services/error';

@Component({
  selector: 'app-error-dialog',
  templateUrl: 'error-dialog.html',
  styleUrls: ['./error.component.scss'],

})
export class ErrorDialogComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public show = false;
  public message = AppConstants.ERROR.MESSAGES.API;


  constructor(
    private readonly errorManager: ErrorManagerService
  ) { }

  public ngOnInit(): void {
    this.subscription = this.errorManager.getErrorObservable().subscribe((state: ErrorObject) => {
      this.message = AppConstants.ERROR.MESSAGES.API;
      ($("#errorModal") as any).modal('show');
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
