// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  b2cForgotPassFlow:'https://clientimpdev.b2clogin.com/clientimpdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PasswordReset&client_id=0eaeb848-4b7c-43a0-b2c6-b73b3fff405a&nonce=defaultNonce&redirect_uri='+window.location.origin+'&scope=openid&response_type=id_token&prompt=login',
  baseUrl:'https://ehciapidev2.empirxhealth.com/api',
  b2cSignInFlow: 'https://clientimpdev.b2clogin.com/clientimpdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignIn&client_id=0eaeb848-4b7c-43a0-b2c6-b73b3fff405a&nonce=defaultNonce&redirect_uri='+window.location.origin+'&scope=openid&response_type=id_token&prompt=login'
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
